<template>
  <v-container>
    <h2 class="red--text mb-5 mt-10 text-center">
      Localize o escritório mais próximo:
    </h2>
    <div id="container-filials">
      <v-col v-for="filial in filiais" :key="filial.title" cols="12">
        <v-row align="center">
          <v-avatar rounded="" class="ml-3" size="80">
            <img :src="filial.img" alt="" />
          </v-avatar>
          <v-col class="ml-10">
            <h3 class="mt-10">{{ filial.title }}</h3>
            <h6>{{ filial.address }}</h6>
            <h6 class="mb-10">{{ filial.number }}</h6>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </div>
    <h2 class="red--text mb-5 mt-10 text-center">Ou envie-nos uma mensagem:</h2>
    <div id="form-container">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="form.name"
              color="red"
              label="Nome"
              maxlength="100"
              required
              clearable
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório!']"
            />
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="form.phone"
              color="red"
              label="Telefone"
              maxlength="15"
              required
              clearable
              outlined
              v-mask="'(##) #####-####'" 
              :rules="[(v) => !!v || 'Campo obrigatório!']"
            />
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="form.email"
              color="red"
              label="Email"
              maxlength="100"
              required
              clearable
              outlined
              :rules="[ v => {
                if(!v) {
                  return 'Campo obrigatório!';
                } else if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
                  return 'E-mail inválido!';
                } else {
                  return true;
                }
              }]"
            />
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-text-field
              v-model="form.subject"
              color="red"
              label="Sobre"
              maxlength="100"
              clearable
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório!']"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.message"
              color="red"
              clearable
              label="Mensagem"
              maxlength="3000"
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório!']"
            />
          </v-col>
          <v-col cols="12">
            <div>
              <vue-recaptcha id="recaptcha" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6LciFx4kAAAAAGnuL7Uw6sTjbJ044dop8mXkmKem"></vue-recaptcha> 
            </div>
          </v-col>
          <v-btn @click="formSendEmail" class="mb-3 white--text mx-auto btn-link" color="red" grow>
            Enviar
          </v-btn>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
#container-filials, #form-container, #recaptcha {
  margin: 0 auto !important;
}

#container-filials {
  max-width: 350px;
}

#form-container {
  max-width: 700px;
}

#recaptcha {
  max-width: 300px;
}
</style>

<script>
import filialCuritiba from "@/assets/filiais/filial_curitiba.gif";
import filialPoa from "@/assets/filiais/filial_poa.gif";
import filialSp from "@/assets/filiais/filial_sp.jpg";
import { sendEmail } from '@/firebase';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Contact",

  components: { 
    VueRecaptcha 
  },

  methods: {
    async formSendEmail() {
      if(!await this.$refs.form.validate()) {
        return;
      }

      if(!this.token) {
        this.$notify({ group: 'foo', type: 'error', title: 'reCAPTCHA', text: 'reCAPTCHA obrigatório.' });
        return;
      }

      try {
        var body = `Nome: ${this.form.name}\nEmail: ${this.form.email}\nTelefone: ${this.form.phone}\nMensagem: ${this.form.message}`;
        await sendEmail('giovani@taxcon.com', this.form.subject, body);
        this.$refs.form.reset();
        this.$refs.recaptcha.reset();
        this.token = null;
        this.$notify({ group: 'foo', type: 'success', title: 'Contato', text: 'Mensagem enviada com sucesso!' });
      } catch (error) {
        this.$notify({ group: 'foo', type: 'error', title: 'Contato', text: 'Erro ao enviar mensagem.' });
        console.error(error);
      }
    },

    onVerify (token) {
      this.token = token;
    },

    onExpired () {
      this.token = null;
    }
  },

  data: () => ({
    filiais: [
      {
        title: "Curitiba",
        address: "Rua Visconde do Rio Branco, 1358, conj. 206",
        number: "Fone: +55 41 3077-7429",
        img: filialCuritiba,
      },
      {
        title: "PORTO ALEGRE",
        address: "Avenida Carlos Gomes, 777, conj. 1506",
        number: "Fone: +55 51 3026-0320",
        img: filialPoa,
      },
      {
        title: "SÃO PAULO",
        address: "Rua Visconde de Indaiatuba, 107",
        number: "Fone: +55 11 3836-0853",
        img: filialSp,
      },
      {
        title: "BRASÍLIA",
        address: "SCN, Q2, Bloco D, Torre B, Conjunto 916",
        number: "Fone: +55 61 3201-7007",
        img: filialCuritiba,
      },
    ],
    form: [],
    token: null,
  }),
};
</script>
