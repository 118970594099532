<template>
  <v-card elevation="0">
    <v-app-bar app hide-on-scroll prominent>
      <v-container id="logo" fill-height>
        <v-img id="logo" max-width="302" src="@/assets/logo.png" />
      </v-container>
      <template v-slot:extension>
        <v-tabs v-model="tab" bg-color="transparent" grow color="red" show-arrows>
          <v-tab  v-for="item in items" :key="item.path" :to="item.path" link>
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </v-card>
</template>

<style scoped lang="scss">
.v-app-bar {
  background-color: white !important;
}

#logo {
  display: flex;
  justify-content: center;
}
</style>

<script>
export default {
  data: () => ({
    tab: "/",
    items: [
      { title: "Home", path: "/" },
      { title: "Quem Somos", path: "/about" },
      { title: "Equipe", path: "/team" },
      { title: "Serviços", path: "/services" },
      { title: "Clientes", path: "/customers" },
      { title: "Contato", path: "/contact" },
    ],
  }),
};
</script>
