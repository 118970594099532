var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',{staticClass:"red--text mb-5 mt-10 text-center"},[_vm._v(" Localize o escritório mais próximo: ")]),_c('div',{attrs:{"id":"container-filials"}},_vm._l((_vm.filiais),function(filial){return _c('v-col',{key:filial.title,attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-avatar',{staticClass:"ml-3",attrs:{"rounded":"","size":"80"}},[_c('img',{attrs:{"src":filial.img,"alt":""}})]),_c('v-col',{staticClass:"ml-10"},[_c('h3',{staticClass:"mt-10"},[_vm._v(_vm._s(filial.title))]),_c('h6',[_vm._v(_vm._s(filial.address))]),_c('h6',{staticClass:"mb-10"},[_vm._v(_vm._s(filial.number))])])],1),_c('v-divider')],1)}),1),_c('h2',{staticClass:"red--text mb-5 mt-10 text-center"},[_vm._v("Ou envie-nos uma mensagem:")]),_c('div',{attrs:{"id":"form-container"}},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"color":"red","label":"Nome","maxlength":"100","required":"","clearable":"","outlined":"","rules":[(v) => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"color":"red","label":"Telefone","maxlength":"15","required":"","clearable":"","outlined":"","rules":[(v) => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"color":"red","label":"Email","maxlength":"100","required":"","clearable":"","outlined":"","rules":[ v => {
              if(!v) {
                return 'Campo obrigatório!';
              } else if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
                return 'E-mail inválido!';
              } else {
                return true;
              }
            }]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('v-text-field',{attrs:{"color":"red","label":"Sobre","maxlength":"100","clearable":"","outlined":"","rules":[(v) => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"color":"red","clearable":"","label":"Mensagem","maxlength":"3000","outlined":"","rules":[(v) => !!v || 'Campo obrigatório!']},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"id":"recaptcha","sitekey":"6LciFx4kAAAAAGnuL7Uw6sTjbJ044dop8mXkmKem"},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}})],1)]),_c('v-btn',{staticClass:"mb-3 white--text mx-auto btn-link",attrs:{"color":"red","grow":""},on:{"click":_vm.formSendEmail}},[_vm._v(" Enviar ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }