<template>
  <v-container>
    <v-row justify="center">
      <h2 class="red--text mb-5 mt-10">Entenda nossos diferenciais:</h2>
      <p class="px-10">
        Com mais de 10 anos de atuação no mercado empresarial, a Taxcon é uma
        conceituada consultoria tributária dedicada a superar resultados em
        razão da alta qualificação dos profissionais que compõem nossa equipe
        multidisciplinar. Os trabalhos são realizados por consultores
        especializados em cada tributo e com larga experiência nas áreas de
        atuação. Para que nossos clientes cuidem de sua atividade principal e
        alcancem suas metas, oferecemos serviços de excelência, com base na
        integridade de nossas relações e credibilidade de nossos trabalhos.
      </p>
    </v-row>
    <v-container class="text-center">
      <h2 class="red--text mb-5 mt-10">Localize nossas sedes:</h2>
      <v-row justify="space-between">
        <v-col
          v-for="filial in filiais"
          :key="filial.title"
          cols="12" md="3" lg="3" xl="3"
          class="mb-10"
        >
          <v-avatar size="80">
            <img :src="filial.img">
          </v-avatar>
          <v-row>
            <v-col>
              <h3>{{ filial.title }}</h3>
              <h6>{{ filial.address }}</h6>
              <h6>{{ filial.number }}</h6>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="text-center">
      <h2 class="red--text mb-5 mt-10">Veja alguns de nossos clientes:</h2>
      <v-row justify="space-between">
        <v-col
          v-for="cliente in clientes"
          :key="cliente"
          cols="12" md="3" lg="3" xl="3"
          class="mb-10"
        >
          <v-avatar size="80">
            <img :src="cliente">
          </v-avatar>
        </v-col>
        <v-btn class="white--text mx-auto btn-link" color="red" to="/customers" grow>
          Ver mais
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import filialCuritiba from "@/assets/filiais/filial_curitiba.gif";
import filialPoa from "@/assets/filiais/filial_poa.gif";
import filialSp from "@/assets/filiais/filial_sp.jpg";

export default {
  name: "Home",

  mounted() {
    const context = require.context("@/assets/home/clientes/", false);
    this.clientes = context.keys().map(context);
  },

  data: () => ({
    filiais: [
      {
        title: "Curitiba",
        address: "Rua Visconde do Rio Branco, 1358, conj. 206",
        number: "Fone: +55 41 3077-7429",
        img: filialCuritiba,
      },
      {
        title: "PORTO ALEGRE",
        address: "Avenida Carlos Gomes, 777, conj. 1506",
        number: "Fone: +55 51 3026-0320",
        img: filialPoa,
      },
      {
        title: "SÃO PAULO",
        address: "Rua Visconde de Indaiatuba, 107",
        number: "Fone: +55 11 3836-0853",
        img: filialSp,
      },
      {
        title: "BRASÍLIA",
        address: "SCN, Q2, Bloco D, Torre B, Conjunto 916",
        number: "Fone: +55 61 3201-7007",
        img: filialCuritiba,
      },
    ],
    clientes: [],
  }),
};
</script>
