<template>
  <v-container class="text-center">
    <h2 class="red--text mb-5 mt-10">Conheça nossa equipe de especialistas:</h2>
    <v-row no-gutters>
      <v-col
        class="px-10 mt-5 text"
        v-for="collaborator in collaborators"
        :key="collaborator.title"
        cols="12"
        md="6"
        lg="6"
        xl="6"
      >
        <div class="text-start">
          <strong>{{ collaborator.title }}</strong>
          <p>
            {{ collaborator.function }}
            <v-divider class="mb-5 mt-5" />
            {{ collaborator.msg }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Team",

  data: () => ({
    collaborators: [
      {
        title: "GIOVANI BOTEON",
        function: "Advogado e Economista",
        msg: "Fundador e responsável técnico da Taxcon Consultoria Tributária. Sediado em Curitiba–PR. Graduado em Economia pela Universidade Tuiutí do Paraná.Graduado em Ciências Jurídicas e Sociais pela Universidade Tuiutí do Paraná.Pós-Graduado em Direito Tributário pela Universidade Federal de Santa Catarina.Possui larga experiência em consultoria fiscal e planejamento tributário, atuando no mercado empresarial há mais de 20 anos.",
      },
      {
        title: "VINÍCIUS DE AZAMBUJA FRANCO",
        function: "Advogado",
        msg: "Responsável pela gestão e atendimento de empresas na Taxcon Consultoria Tributária. Sediado em Porto Alegre–RS.Graduado em Ciências Jurídicas e Sociais pela Pontifícia Universidade Católica do Rio Grande do Sul.Pós-Graduado em Direito Tributário pela Universidade Federal do Rio Grande do Sul (UFRGS).Membro de Comissão Especial da Ordem dos Advogados do Brasil – RS.Possui experiência em Direito Tributário, com ênfase no atendimento e orientação de empresas.",
      },
      {
        title: "KARLA ZANCHETTIN",
        function: "Advogada",
        msg: 'Responsável pelo contencioso tributário e processo administrativo fiscal da Taxcon Consultoria Tributária. Sediada em Curitiba – PR.Graduada pela Faculdade de Direito de Curitiba.Pós-Graduada em Direito Societário pelas Faculdades Integradas Curitiba.Extensão em Contabilidade Gerencial e Auditoria pela FAE.Legal Law Master in Business & Law pelo IBMEC.Co-autora do livro "Gestão e Direito Corporativo".Tem experiência superior a 15 anos em Direito Tributário e Societário.',
      },
      {
        title: "RENATO VICENTE CALIXTO",
        function: "Contador",
        msg: "Consultor Tributário da área de tributos indiretos da Taxcon Consultoria Tributária. Sediado em Curitiba - PR.Graduado em contabilidade pela Universidade Paranaense - Unipar.Pós-Graduado em Controladoria pela Universidade Federal do Paraná.Possui experiência de 07 anos em empresa do terceiro setor e expressiva atuação em consultoria fiscal e tributária.",
      },
      {
        title: "CARLOS EDUARDO CRUZ MORESCHI",
        function: "Administrador de Empresas",
        msg: "Consultor Tributário da área de tributos diretos e indiretos da Taxcon Consultoria Tributária. Sediado em Curitiba - PR.Graduado em administração de empresas pela FAE Business School – Curitiba - PR.Possui experiência de 20 anos em consultoria fiscal e tributária",
      },
      {
        title: "ANDRÉ WERKA",
        function: "Gestor de Recursos Humanos e Técnico Contábil",
        msg: "Consultor Tributário na área previdenciária da Taxcon Consultoria Tributária. Sediado em Curitiba-PR.Graduado em Gestão de Recursos Humanos pela Faculdades Santa Cruz.Graduado em Técnico Contábil pela ET- Universidade Federal do Paraná.Pós Graduação em MBA Controller pela Faculdades Santa Cruz.Possui experiência de 12 anos na área de Recursos Humanos e Previdenciária.",
      },
      {
        title: "WILLIAN CROSEWSKI",
        function: "Contador",
        msg: "Consultor Tributário da área de tributos diretos e indiretos da Taxcon Consultoria Tributária.Sediado em São Paulo-SP.Graduado em contabilidade pela Faculdades Integradas do Brasil – Unibrasil.Possui experiência na área contábil-fiscal e expressiva atuação em consultoria fiscal e tributária.",
      },
      {
        title: "BENILDE RUBIO",
        function: "Advogada",
        msg: "Consultora Tributária na área de tributos indiretos da Taxcon Consultoria Tributária. Sediada em Curitiba-PR.Graduada em Direito pela Universidade do Oeste Paulista (UNOESTE).Pós-Graduada em Direito Empresarial e Tributário pela Instituição Toledo de Ensino (ITE).Atua há 15 anos como Consultora Tributária e Fiscal, com ênfase nos seguintes tributos: ICMS, IPI e ISS.",
      },
      {
        title: "DANIEL MARCHI",
        function: "Contador e Administrador de Empresas",
        msg: "Responsável técnico da área financeira da Taxcon Consultoria Tributária. Sediado em Porto Alegre–RS.Graduado em Contabilidade pela Universidade Federal do Rio Grande do Sul (UFRGS).Graduado em Administração de Empresas pela Universidade Federal do Rio Grande do Sul (UFRGS).Pós-Graduado em Finanças pela Universidade Federal do Rio Grande do Sul (UFRGS).Pós-Graduado em Auditoria pelo Banco Central do Brasil.Possui larga experiência em projetos de incentivos fiscais e financeiros, atuando no mercado empresarial há mais de 35 anos.",
      },
      {
        title: "DEISON MARCHI",
        function: "Contador",
        msg: "Consultor técnico da área financeira da Taxcon Consultoria Tributária. Sediado em Porto Alegre–RS.Graduado em Ciências Contábeis pela Faculdade Portoalegrense (FAPA).Pós-Graduando em Gestão Estratégica de Tributos pela Faculdade de Desenvolvimento do Rio Grande do Sul (FADERGS).Possui vasta experiência em projetos de incentivos fiscais e financeiros, atuando no mercado empresarial há mais de 10 anos.",
      },
    ],
  }),
};
</script>
