<template>
  <v-app>
    <LayoutHeader />
    <v-main>
      <notifications group="foo" />
      <img v-if="this.$route.path == '/'" id="banner" src="@/assets/home/banner.jpg" />
      <router-view/>
    </v-main>
    <LayoutFooter />
  </v-app>
</template>

<style lang="scss">
  * {
    font-family: "Circular-Loom", Times, serif !important;
  }

  #banner {
    width: 100% !important;
    max-height: 300px !important;
  }

  p {
    text-align: justify !important;
  }
</style>

<script>
import LayoutHeader from "@/components/Layout/LayoutHeader";
import LayoutFooter from "@/components/Layout/LayoutFooter";

export default {
  name: 'App',

  components: {
    LayoutHeader,
    LayoutFooter
  },

  data: () => ({
    //
  }),
};
</script>
