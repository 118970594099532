<template>
  <v-container class="text-center">
    <h2 class="red--text mb-5 mt-10">Consulte nossos principais serviços:</h2>
    <v-row no-gutters>
      <v-col v-for="service in services" :key="service.title" cols="12">
        <h5 class="red--text mt-10">{{ service.title }}</h5>
        <p class="px-10">
          {{ service.msg }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Services",

  data: () => ({
    services: [
      {
        title: "CONSULTORIA TRIBUTÁRIA",
        msg: "Diagnóstico fiscal: análise e identificação de alternativas legais para otimização da carga tributária.Revisão dos métodos de apuração e recolhimento exigidos pela legislação do PIS e COFINS.Revisão previdenciária: análise das bases de cálculo de tributos incidentes sobre a folha de salários.Revisão dos métodos de apuração e recolhimento exigidos pela legislação do ICMS e IPI.Revisão dos métodos de apuração e recolhimento exigidos pela legislação do IRPJ e CSLL.Revisão e implementação de incentivos fiscais relativos ao Programa de Desenvolvimento e Inovação – Lei do Bem.",
      },
      {
        title: "CONSULTORIA FISCAL",
        msg: "Consultoria permanente para Escrituração Contábil Fiscal - ECF, Sped, Lalur e eSocial.Consultoria permanente sobre impostos indiretos e diretos.Revisão e implementação de incentivos fiscais.Elaboração de pareceres e opiniões legais.Estudos de Regimes Especiais Tributários.",
      },
      {
        title: "TERCEIRIZAÇÃO",
        msg: "Disponibilização de consultores especializados para trabalhos in company.Consultores terceirizados in company na área contábil e fiscal.Consultores terceirizados in company na área administrativa e financeira.Consultores terceirizados in company na área de recebimento e faturamento.Consultores terceirizados in company na área de recursos humanos e previdenciária.Gestão corporativa, com treinamento e monitoramento contínuo dos consultores disponibilizados aos clientes.",
      },
      {
        title: "CLASSIFICAÇÃO FISCAL",
        msg: "Enquadramento fiscal de mercadorias na TIPI, TEC - NCM & NALADI/SH.Indicação dos Ex-Tarifários - Ocorrências e Enquadramentos.Indicação das alíquotas dos tributos (II, IPI, PIS/PASEP, COFINS).Consultoria e Pareceres referentes à correta Classificação Fiscal de Mercadorias - NCM.Confecção de Consultas de Classificação Fiscal de Mercadorias junto à Secretaria da Receita Federal do Brasil.",
      },
      {
        title: "CONTENCIOSO TRIBUTÁRIO",
        msg: "Contencioso AdministrativaDefesas em atuações fiscais e notificações fiscais de lançamentos de débitos.Manifestações de inconformidade e elaboração de pedidos de restituição e ressarcimento.Elaboração de consultas de interpretação da legislação tributária.Obtenção de certidões negativas de débito ou certidões positivas com efeitos de negativas.Acompanhamento e suporte em fiscalizações e diligências fiscais.Processos em Regimes Especiais Tributários.Contencioso JudicialAdvocacia tributária e fiscal preventiva.Ações declaratórias de direito e constituição de créditos tributários e fiscais.Mandados de segurança em matéria tributária.Ações anulatórias de débito fiscal.Defesas de contribuintes em processos de execução fiscal.Recursos perante tribunais de segunda instância e superiores.",
      },
      {
        title: "CONSULTORIA FINANCEIRA",
        msg: "Elaboração e acompanhamento de projetos de financiamentos junto à FINEP.Elaboração e acompanhamento de projetos de financiamentos junto ao BNDES.Elaboração e acompanhamento de projetos de Financiamento de Máquinas e Equipamentos - FINAME.Elaboração e acompanhamento de projetos de Financiamento a Empreendimentos - FINEM.Revisão e implementação de incentivos financeiros.",
      },
    ],
  }),
};
</script>
