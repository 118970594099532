<template>
  <v-footer padless>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4" lg="4" xl="4">
          <h3 class="mb-5">Quem somos</h3>
          <h6>
            A Taxcon é uma conceituada empresa de consultoria tributária cujo
            foco é a gestão estratégica nas áreas tributária, fiscal,
            previdenciária e a terceirização de nossos consultores in company.
            Possuímos escritórios em São Paulo, Curitiba e Porto Alegre, além de
            expressiva atuação em várias regiões do Brasil.
          </h6>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
          <h3 class="mb-5 red--text">Serviços</h3>
          <v-row>
            <v-col
              cols="6"
              v-for="names, index  in services" 
              :key="index"
              class="services"
            >
              <div>
                <h5
                  v-for="name in names"
                  :key="name"
                >
                  <a href="/services">{{ name }}</a>
                </h5>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col id="logo" cols="12" md="4" lg="4" xl="4">
          <v-img max-width="256" src="@/assets/logo10anos.png" />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
  .v-footer {
    border-top: ridge red;
    background-color: white !important;

    .container {
      width: 60%;

      h6 {
        text-align: justify;
      }
    }
  }

  @media(max-width: 960px) {
    #logo, .services {
      display: flex;
      justify-content: center;
    }

    .services {
      text-align: left;
    }

    .container {
      width: 80% !important;
    }
  }
</style>

<script>
export default {
  data: () => ({
    services: [
      [
        'Consultoria Tributária',
        'Terceirização',
        'Contencioso Tributário',
      ],
      [
        'Consultoria Fiscal',
        'Classificação Fiscal',
        'Consultoria Financeira'
      ],
    ],
  }),
};
</script>