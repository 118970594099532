<template>
  <v-container class="text-center">
    <h2 class="red--text mb-5 mt-10">Veja alguns de nossos clientes:</h2>
    <v-row>
      <v-col
        v-for="cliente in clientes"
        :key="cliente"
        justify="space-between"
        cols="12"
        md="3"
        lg="3"
        xl="3"
      >
        <img :src="cliente" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.row {
  width: 60% !important;
  margin: 0 auto;
  img {
    width: 150px;
    padding: 25px;
  }
}

@media(max-width: 1264px) {
  .row {
    width: 80% !important;
  }
}
</style>

<script>
export default {
  name: "Customers",

  mounted() {
    const context = require.context("@/assets/clientes/", false);
    this.clientes = context.keys().map(context);
  },

  data: () => ({
    clientes: [],
  }),
};
</script>
