<template>
  <v-container>
    <v-row justify="center">
      <h2 class="red--text mb-5 mt-10">Saiba o nosso perfil:</h2>
      <p class="px-10">
        A Taxcon é uma conceituada empresa de consultoria tributária cujo foco é
        a gestão estratégica nas áreas tributária, fiscal e previdenciária, e a
        terceirização de nossos consultores in company. Possuímos escritórios em
        São Paulo, Curitiba e Porto Alegre, além de expressiva atuação em várias
        regiões do Brasil. Nossa equipe conta com profissionais formados e
        especializados em diversas áreas de direito empresarial, contabilidade,
        economia e recursos humanos, com expertise e atuação no mercado há mais
        de 20 anos. Segurança, credibilidade e transparência são os nossos
        principais pilares. Buscamos a rápida e satisfatória solução para nossos
        clientes com a superação de resultados em razão da alta qualificação dos
        profissionais que compõem a nossa equipe.
      </p>
    </v-row>
    <v-row no-gutters>
      <v-col
        class="px-10 mt-16 mb-10"
        v-for="text in texts"
        :key="text.title"
        cols="12"
        md="4" 
        lg="4"
        xl="4"
      >
        <p>
          <strong>{{ text.title }}</strong>
          <v-row class="px-3 mt-5">
            {{ text.msg }}
          </v-row>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",

  data: () => ({
    texts: [
      {
        title: "Missão",
        msg: "Oferecer serviços de excelência, otimizando recursos e garantindo a sustentabilidade, em um ambiente de desenvolvimento e prosperidade para todos.",
      },
      {
        title: "Visão",
        msg: "Conquistar a cada dia a admiração e o respeito dos seus clientes e colaboradores, atuando entre as melhores e mais sólidas empresas de consultoria tributária do Brasil.",
      },
      {
        title: "Valores",
        msg: "Nosso ambiente é de simplicidade: somos práticos e ágeis em nossas ações.Valorizamos a integridade de nossas relações e acreditamos no potencial humano para gerar resultado aos clientes.",
      },
    ],
  }),
};
</script>
